import { ArrowDownOutlined } from '@ant-design/icons';
import { Card, Space } from 'antd';
import { DataExtractionCard } from './DataExtractionCard';
import { DataIntegrationCard } from './DataIntegrationCard';
import { DataNormalizationCard } from './DataNormalizationCard';
import { DataValidationCard } from './DataValidationCard';
import { UploadCard } from './UploadCard';
import { useEffect, useState } from 'react';
import { Tips } from './Tips';

export default function ProcessingTab({ value, onChange, minimalMode }: any) {
  const [showRightComponent, setShowRightComponent] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const availableSpace = window.innerWidth - 900;
      setShowRightComponent(availableSpace > 300);
    };

    handleResize(); // Check on initial render
    window.addEventListener('resize', handleResize); // Check on resize

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      style={
        minimalMode ? {} : { display: 'flex', width: '100%', padding: '0 16px' }
      }
    >
      <Space
        direction="vertical"
        style={
          minimalMode
            ? {}
            : {
                width: '100%',
                maxWidth: '900px',
                maxHeight: 'calc(100vh - 160px)',
                overflowY: 'auto',
              }
        }
      >
        <UploadCard value={value} onValueChange={onChange} />
        <ArrowDownOutlined style={{ marginLeft: '80px' }} />

        <DataExtractionCard value={value} onValueChange={onChange} />
        <ArrowDownOutlined style={{ marginLeft: '80px' }} />

        <DataNormalizationCard value={value} onValueChange={onChange} />
        <ArrowDownOutlined style={{ marginLeft: '80px' }} />

        <DataValidationCard value={value} onValueChange={onChange} />

        <ArrowDownOutlined style={{ marginLeft: '80px' }} />

        <DataIntegrationCard value={value} onValueChange={onChange} />
      </Space>
      {showRightComponent && !minimalMode && (
        <div
          style={{
            flex: 1,
            maxHeight: 'calc(100vh - 160px)',
            overflowY: 'auto',
          }}
        >
          <Tips value={value} onValueChange={onChange} />
        </div>
      )}
    </div>
  );
}

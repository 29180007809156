import { message } from 'antd';
import axios from 'axios';
import { CaseModel, RuleCatalogItem } from 'digicust_types';
import { GENERIC_URL } from './constants';

export interface Case {
  customerId?: string;
  createdAt?: string;
  count: number;
}

export class AdminRepository {
  public static getOverview = async (): Promise<any> => {
    try {
      const response = await axios.post(
        `${GENERIC_URL}/api/admin/get-overview`,
      );
      return response.data;
    } catch (error) {
      console.log(error);
      message.error('Something went wrong.');
      return {};
    }
  };

  public static getCaseCondensed = async (
    caseId: string,
    options?: { suppressErrorMessage: boolean },
  ): Promise<CaseModel> => {
    try {
      const response = await axios.post(
        `${GENERIC_URL}/api/admin/get-case-condensed/${caseId}`,
      );
      return response.data;
    } catch (error) {
      console.log(error);
      !options?.suppressErrorMessage && message.error('Something went wrong.');
      return {};
    }
  };

  public static addRuleToRuleCatalog = async (
    ruleCatalogItem: RuleCatalogItem,
  ): Promise<RuleCatalogItem[]> => {
    try {
      const response = await axios.post(
        `${GENERIC_URL}/api/admin/add-rule-to-rule-catalog`,
        ruleCatalogItem,
      );
      return response.data;
    } catch (error) {
      console.log(error);
      message.error('Something went wrong.');
      return [];
    }
  };

  public static triggerCase = async (
    caseId: string,
    env: string,
  ): Promise<any> => {
    try {
      const response = await axios.post(
        `${GENERIC_URL}/api/admin/trigger-case/${caseId}/${env}`,
      );
      return response.data;
    } catch (error) {
      console.log(error);
      message.error('Something went wrong.');
      return {};
    }
  };

  public static getAllCustomers = async (): Promise<
    { id: string; name: string }[]
  > => {
    try {
      const response = await axios.post(`${GENERIC_URL}/api/admin/customers`);
      return response.data;
    } catch (error) {
      console.log(error);
      message.error('Something went wrong.');
      return [];
    }
  };

  public static getAllCustomerProjects = async (
    customerId: string,
  ): Promise<any> => {
    try {
      const response = await axios.post(
        `${GENERIC_URL}/api/admin/customers/${customerId}/projects`,
      );
      return response.data;
    } catch (error) {
      console.log(error);
      message.error('Something went wrong.');
      return {};
    }
  };

  public static addToCustomerAndProject = async (
    customerId: string,
    projectId: string,
    userId: string,
  ): Promise<any> => {
    try {
      const response = await axios.post(
        `${GENERIC_URL}/api/admin/add-to-customer-and-project/${customerId}/${projectId}/${userId}`,
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data || 'something went wrong');
    }
  };

  public static getAllCustomersFiltered = async (
    filter: string,
  ): Promise<any> => {
    try {
      const response = await axios.get(
        `${GENERIC_URL}/api/admin/get-allCustomers/${filter}`,
      );
      return response.data;
    } catch (error) {
      console.log(error);
      message.error('Something went wrong.');
      return {};
    }
  };

  public static getCaseList = async (filter: string): Promise<CaseModel[]> => {
    try {
      const response = await axios.get(
        `${GENERIC_URL}/api/admin/case-list/${filter}`,
      );
      return response.data;
    } catch (error) {
      console.log(error);
      message.error('Something went wrong.');
      return [];
    }
  };

  public static getCaseCountList = async (filter: string) => {
    try {
      // const GENERIC_URL = 'http://localhost:3005';
      const response = await axios.get(
        `${GENERIC_URL}/api/admin//customers-case-upload-counts/${filter}`,
      );
      return response.data;
    } catch (error) {
      console.log(error);
      message.error('Something went wrong.');
      return [];
    }
  };

  public static getCustomerById = async (customerId: string): Promise<any> => {
    try {
      const response = await axios.get(
        `${GENERIC_URL}/api/admin/customer/${customerId}/customer-name`,
      );
      return response.data;
    } catch (error) {
      console.log(error);
      message.error('Something went wrong.');
      return {};
    }
  };

  public static getProjectById = async (
    customerId: string,
    projectId: string,
  ): Promise<any> => {
    try {
      const response = await axios.get(
        `${GENERIC_URL}/api/admin/customer/${customerId}/project/${projectId}/project-name`,
      );
      return response.data;
    } catch (error) {
      console.log(error);
      message.error('Something went wrong.');
      return {};
    }
  };

  public static getGlobalSetting = async (settingKey: string): Promise<any> => {
    try {
      const response = await axios.get(
        `${GENERIC_URL}/api/admin/global-setting/${settingKey}`,
      );
      return response.data;
    } catch (error) {
      console.log(error);
      message.error('Something went wrong.');
      return {};
    }
  };

  public static saveGlobalSetting = async (setting: any): Promise<any> => {
    try {
      const response = await axios.post(
        `${GENERIC_URL}/api/admin/global-setting`,
        setting,
      );
      return response.data;
    } catch (error) {
      console.log(error);
      message.error('Something went wrong.');
      return {};
    }
  };
}
